/** @format */

import { ENUM_ADMIN_ROLES } from "@prisma/client";
import { useAdminSignin } from "@roadflex/react-hooks";
import { setCookie } from "@roadflex/web-lib";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import SignInUI from "./sign-in.ui";
/* eslint-disable-next-line */
export interface SignInProps {}

export function SignIn(props: SignInProps) {
  const router = useRouter();

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    ...rest
  } = useAdminSignin({
    initialValues: {
      email: "",
      password: "",
      otp: "",
      isOtpVerify: false,
    },
    onSubmit(res, error) {
      if (error) {
        toast.error(error.message);
        return;
      }
      if (res && res.data) {
        const { authToken, firstName, lastName, id, roles } =
          res.data.adminSignin;
        if (values.isOtpVerify || roles.includes(ENUM_ADMIN_ROLES.SUPERADMIN)) {
          window.localStorage.setItem("firstName", firstName);
          window.localStorage.setItem("lastName", lastName);
          setCookie("adminToken", authToken, 30);
          if (!roles.includes(ENUM_ADMIN_ROLES.SUPERADMIN)) {
            router.push("/onboard");
          }
          router.push("/dashboard");
        } else {
          setFieldValue("isOtpVerify", true);
        }
      } else {
        toast.error("Something went wrong");
      }
    },
  });

  return (
    <SignInUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        handleBlur,
        setFieldValue,
        ...rest,
      }}
    />
  );
}

export default SignIn;
